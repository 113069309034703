<script>
export default {
  name: 'TicketCreationEdition',
  props: {
    ticketPosition: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  created() {
    this.$router.push({
      name: 'public-create-ticket',
      params: {
        ticketPosition: this.ticketPosition,
        title: this.title,
        message: this.message,
      },
    });
  }
};
// http://localhost:5005/create-ticket-redir?t=Renouveler%20le%20stock%20de%20PQ&m=Renouveler%20le%20stock%20de%20PQ%20dans%20les%20toilettes%20H%20ascenseur%20du%20R-1&r=ROOM-2e07-4847-b28d-0b955cf81175&b=BUILDING-cb51-4ec1-a0e4-5782a0d26c55&f=FLOOR-9f4d-4b50-ab15-93c75ba1e853&x=83&y=492
</script>

<style lang="stylus" scoped>
@import '~variables'

.title
  font-weight 400
  margin $space-2
  width 100%
  font-size $fs-h1

</style>
